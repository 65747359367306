import { moduleState } from 'cerebral'
import { PDFDocument, degrees } from 'pdf-lib'
import { convertInchesToPDFLibUnit } from '../../lib/util/pdfmake-props'
import { upload, url } from '../../lib/util/cloudinary'
import { crudActions } from '../factories'
import { captureException } from '../../lib/util/errors'
import { DesignHuddleClient } from '../../lib/util/designhuddle'
import apiClient from '../../lib/util/api-client'

const designHuddleService = apiClient.service('publications/designhuddle')

export const getEditorToken = async ({ props: data }) => designHuddleService.create(data)

const getPdfArrayBuffer = async (pdfDocumentDefinition) => {
  // Async import for code-splitting.
  const { default: pdfMake } = await import('../../lib/util/pdfmake')

  return new Promise((resolve, reject) => {
    const pdf = pdfMake.createPdf(pdfDocumentDefinition)
    pdf.getBuffer(
      (blob) => resolve(blob),
      (error) => reject(error)
    )
  })
}

const scaleCalendarPages = (pages, paperOrientation) => {
  return pages.map((page) => {
    if (paperOrientation === 'portrait') {
      page.setSize(convertInchesToPDFLibUnit(8.5), convertInchesToPDFLibUnit(11))
      // reducing 11 X 18 to 8.5 X 17 in percentage
      page.scaleContent(0.77, 0.65)
    } else if (paperOrientation === 'landscape') {
      page.setSize(convertInchesToPDFLibUnit(11), convertInchesToPDFLibUnit(8.5))
      // reducing 18 X 11 to 11 X 8.5 in percentage
      page.scaleContent(0.64, 0.77)
      page.setRotation(degrees(90))
    }
    return page
  })
}

export const savePDF = async ({ props, app, publicationsService, store }) => {
  const getEditorToken = app.getSequence(`publications.getEditorToken`)
  const {
    entities = {},
    calendarDocDefinition = {},
    values: { editableLayout: { projectId: editorProjectId } = {}, updateDownloadablePdf = false, calendarOptions = {} } = {},
  } = props

  const { publications: [{ _id, includeCalendar = false, title, isFoldableBooklet } = {}] = [] } = entities

  if (editorProjectId && updateDownloadablePdf) {
    try {
      store.set(moduleState`pdf.generating`, true)

      const { access_token } = await getEditorToken()
      const designHuddleClient = new DesignHuddleClient(access_token)
      const editorProject = await designHuddleClient.exportProject(editorProjectId, title)

      let publication = {}

      if (editorProject) {
        // create a new pdf-lib document
        let newPdfDoc = await PDFDocument.create()
        // load the editor pdf into the pdf-lib document object
        const editorPdfBytes = await fetch(editorProject.download_url).then((res) => res.arrayBuffer())
        const editorPdfDoc = await PDFDocument.load(editorPdfBytes)

        // get editor pdf pages and page count
        let pages = editorPdfDoc.getPages()
        const pageCount = editorPdfDoc.getPageCount()

        if (isFoldableBooklet) {
          // Ensure pages are a multiple of 4 for booklet printing
          const remainder = includeCalendar ? (pageCount + 2) % 4 : pageCount % 4

          if (remainder !== 0) {
            const emptyPagesToAdd = Array(4 - remainder).fill(null)
            pages.splice(pageCount - 1, 0, ...emptyPagesToAdd)
          }

          // Arrange pages for booklet printing
          let left = 0
          let right = pages.length - 1
          let isFront = true

          while (left < right) {
            const pageLeft = pages[left]
            const pageRight = pages[right]

            const page = newPdfDoc.addPage([
              //
              convertInchesToPDFLibUnit(17),
              convertInchesToPDFLibUnit(11),
            ])

            const [frontPage, backPage] = isFront ? [pageRight, pageLeft] : [pageLeft, pageRight]

            if (frontPage) {
              const embeddedFrontPage = await newPdfDoc.embedPage(frontPage)
              page.drawPage(embeddedFrontPage, { x: 0, y: 0 })
            }

            if (backPage) {
              const embeddedBackPage = await newPdfDoc.embedPage(backPage)
              page.drawPage(embeddedBackPage, {
                //
                x: convertInchesToPDFLibUnit(8.5),
                y: 0,
              })
            }

            isFront = !isFront

            left++
            right--
          }

          if (includeCalendar && calendarDocDefinition) {
            // load the calendar pdf into the pdf-lib document object
            const calendarPdfBuffer = await getPdfArrayBuffer(calendarDocDefinition)
            const calendarPdfDoc = await PDFDocument.load(calendarPdfBuffer)

            const calendarPageCount = calendarPdfDoc.getPageCount()
            let calendarPageOne = null
            let calendarPageTwo = null

            if (calendarPageCount < 2) {
              const pages = await newPdfDoc.copyPages(calendarPdfDoc, [0])
              calendarPageOne = pages[0]
            } else {
              const pages = await newPdfDoc.copyPages(calendarPdfDoc, [0, 1])
              calendarPageOne = pages[0]
              calendarPageTwo = pages[1]
            }

            if (calendarOptions.paperOrientation === 'portrait') {
              calendarPageOne.setRotation(degrees(90))
              if (calendarPageTwo) {
                calendarPageTwo.setRotation(degrees(90))
              }
            }

            const insertIndex = newPdfDoc.getPageCount()
            await newPdfDoc.insertPage(insertIndex, calendarPageOne)
            if (calendarPageTwo) {
              await newPdfDoc.insertPage(insertIndex + 1, calendarPageTwo)
            }
          }
        } else {
          newPdfDoc = editorPdfDoc
          // prepare pdf for non booklet printing

          if (includeCalendar) {
            // load the calendar pdf into the pdf-lib document object
            const calendarPdfBuffer = await getPdfArrayBuffer(calendarDocDefinition)
            const calendarPdfDoc = await PDFDocument.load(calendarPdfBuffer)

            const calendarPageCount = calendarPdfDoc.getPageCount()

            let calendarPageOne = null
            let calendarPageTwo = null

            if (calendarPageCount < 2) {
              const pages = await editorPdfDoc.copyPages(calendarPdfDoc, [0])
              const pagesScaled = scaleCalendarPages(pages, calendarOptions.paperOrientation)
              calendarPageOne = pagesScaled[0]
            } else {
              const pages = await editorPdfDoc.copyPages(calendarPdfDoc, [0, 1])
              const pagesScaled = scaleCalendarPages(pages, calendarOptions.paperOrientation)
              calendarPageOne = pagesScaled[0]
              calendarPageTwo = pagesScaled[1]
            }

            // insert the calendar to the end of newsletter pdf
            const calendarPageIndex = editorPdfDoc.getPageCount()
            await editorPdfDoc.insertPage(calendarPageIndex, calendarPageOne)
            if (calendarPageTwo) {
              await editorPdfDoc.insertPage(calendarPageIndex + 1, calendarPageTwo)
            }
          }
        }

        const pdfBytes = await newPdfDoc.save()
        const blob = new Blob([new Uint8Array(pdfBytes)], { type: 'application/pdf' })

        // Uncomment to test without uploading.
        // const pdfUrlData = URL.createObjectURL(blob)
        // return store.set(moduleState`pdf.generating`, false) || window.open(pdfUrlData)

        const signUpload = app.getSequence('uploads.signUpload')

        const pdfDownload = await upload(blob, { signUpload, uploadPreset: 'editor-pdf', publicId: _id })
        const downloadUrl = url({ fl_attachment: title, format: 'pdf' }, pdfDownload)

        publication = await publicationsService.patch(_id, { pdfDownload, downloadUrl })
      }

      store.set(moduleState`pdf.generating`, false)
      return { entities: { ...entities, publications: [publication] } }
    } catch (error) {
      store.set(moduleState`pdf.generating`, false)
      captureException(error)
    }
  }
}

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'publications' })
