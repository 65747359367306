import { moduleState, state } from 'cerebral'
import { DateTime } from 'luxon'
import * as rrule from 'rrule'
const { RRule, RRuleSet } = rrule
import { compose, find as rFind, pick, propEq, omit, pluck, sortBy, prop, uniqBy, path } from 'ramda'
import apiClient from '../../lib/util/api-client'
import { crudActions } from '../factories'
import { mapEventLabel } from '../../lib/util/event-label'

const holidaysService = apiClient.service('events/holidays')
const themesService = apiClient.service('calendar-themes')

export const { find, setList, clearList, get, save, remove } = crudActions({ name: 'events' })

const timeParts = pick(['hour', 'minute', 'second'])
const getThemeTypes = compose(sortBy(prop('name')), uniqBy(prop('_id')), pluck('type'))

export const togglePublished = async ({ props: { id }, eventsService: service, get }) => {
  if (id) {
    const published = get(moduleState`entities.${id}.published`)
    const data = await service.patch(id, { published: !published })
    return { entities: { events: [data] } }
  }
}

export const setEditRangeVisible = ({ props: { visible }, store }) => {
  store.set(moduleState`editRangeVisible`, visible)
}

export const setCalendarState = ({ props: { initialDate, initialView }, get, store }) => {
  const calendarState = get(moduleState`calendar`)
  store.set(moduleState`calendar`, { ...calendarState, initialDate, initialView })
}

export const findHolidays = async ({ props: { query } }) => {
  const holidays = await holidaysService.find({ query })
  holidays.forEach((holiday) => {
    holiday.color = '#000000'
    holiday.editable = false
    holiday.allDay = true
  })
  return { holidays }
}

export const findThemes = async ({ props: { query = {} }, store }) => {
  const { data: themes = [] } = await themesService.find({ query })
  const types = getThemeTypes(themes)
  store.set(moduleState`calendarThemeTypes`, types)
  store.set(moduleState`calendarThemes`, themes)
}

const setPrintOption = (name) => ({ props: { value }, store }) => {
  store.set(moduleState`calendarPrint.${name}`, value)
}
export const setPaperSizeName = setPrintOption('paperSizeName')
export const setFontSizeName = setPrintOption('fontSizeName')
export const setPaperOrientation = setPrintOption('paperOrientation')
export const setPrintSingleLine = setPrintOption('printSingleLine')
export const setHideEventDescription = setPrintOption('hideEventDescription')
export const setEventTypeHidden = setPrintOption('hideEventType')
export const setEventLocationHidden = setPrintOption('hideEventLocation')
export const setBirthdaysShown = setPrintOption('showBirthdays')
export const setEventCalendars = setPrintOption('eventCalendars')
export const setWeeklyCalendarLayout = setPrintOption('weeklyCalendarLayout')

export const selectCalendarTheme = ({ props: { id }, get, store }) => {
  const theme = rFind(propEq(id, '_id'), get(moduleState`calendarThemes`))
  store.set(moduleState`calendarPrint.theme`, theme || null)
}

export const changeEventTime = async ({ props: { _id, originStart, delta, editing = {} }, get, eventsService }) => {
  const timezone = get(state`account.currentUser.timezone`)
  const event = get(state`events.entities.${_id}`)
  originStart = DateTime.fromMillis(originStart, { zone: timezone })
  let { startAt, endAt, repeatType } = event
  const originEnd = originStart.set(timeParts(DateTime.fromJSDate(endAt, { zone: timezone })))

  // Only modify single occurrences or events which do not repeat.
  if (!repeatType || repeatType === 'none' || editing.range === 'this') {
    startAt = originStart.plus(delta)
    endAt = originEnd.plus(delta)
    if (editing.range === 'this') {
      editing.instance = originStart.toISODate()
      editing.targetInstance = startAt.toISODate()
    }
    const result = await eventsService.patch(_id, { ...event, startAt, endAt, editing })
    return { values: result }
  }
}

export const mapCalendarFields = ({ props: { entities }, get }) => {
  const timezone = get(state`account.currentUser.timezone`)
  const labels = get(state`account.eventLabels`)
  const eventLocationCode = get(state`account.eventLocationCode`)
  const calendars = get(state`calendars.entities`)

  let { events } = entities
  const typeCache = {}
  const locations = {}

  events = events.map((event) => {
    let {
      _id: id,
      title,
      locationShortCode,
      startAt,
      endAt,
      eventType,
      eventLabelRef,
      allDay,
      repeatRule,
      repeatRuleExceptDates,
      calendars: assignedCalendars = [],
    } = event
    startAt = DateTime.fromJSDate(startAt).setZone(timezone)
    endAt = DateTime.fromJSDate(endAt).setZone(timezone)

    if (eventLocationCode && locationShortCode) {
      if (event.location) {
        locations[locationShortCode] = event.location
      }
      event = { ...event, title: `${title} [${locationShortCode}]` }
    }

    // Leave out irrelevant fields or defaults from schema casting.
    event = { ...omit(['rrule', 'startAt', 'endAt', 'repeatRuleExceptDates'], event), id }
    const start = allDay ? startAt.toISODate() : startAt.toISO()
    // End date is exclusive. See https://fullcalendar.io/docs/event-object
    const end = allDay ? endAt.plus({ day: 1 }).toISODate() : endAt.toISO()
    event = { ...event, startISO: start, start, end }
    if (repeatRule) {
      event.rrule = RRule.parseString(repeatRule)
      // RRule.js works best with UTC dates.
      event.rrule.dtstart = startAt.setZone('utc', { keepLocalTime: true }).toJSDate()
      event.rrule.until = endAt.endOf('day').setZone('utc', { keepLocalTime: true }).toJSDate()

      // If there are any exceptions, add those to our handling of repeating dates.
      if (repeatRuleExceptDates) {
        const exceptions = repeatRuleExceptDates
          .map((date) => DateTime.fromJSDate(date).setZone('utc').set(timeParts(startAt)).toJSDate())
          .filter((date) => date >= startAt && date <= endAt)
        if (exceptions.length > 0) {
          const rruleSet = new RRuleSet()
          rruleSet.rrule(
            new RRule({
              ...event.rrule,
              dtstart: startAt.setZone('utc', { keepLocalTime: true }).toJSDate(),
              until: endAt.setZone('utc', { keepLocalTime: true }).toJSDate(),
            })
          )
          exceptions.forEach((date) => rruleSet.exdate(date))
          // FullCalendar cannot process exceptions, unless in string form.
          event.rrule = rruleSet.toString()
        }
      }
      event.duration = allDay ? { days: 1 } : pick(['hours', 'minutes'], endAt.diff(startAt, ['days', 'hours', 'minutes']))
    }

    const getEventTypes = (calendarId) => path([calendarId, 'eventTypes'], calendars) || []

    // Set event color based on the resort settings.
    const labelKey = `${eventType}:${eventLabelRef}`
    const calendarEventTypes = assignedCalendars.length ? assignedCalendars.map(getEventTypes).flat().filter(Boolean).map(mapEventLabel) : []
    const typeLabels = calendarEventTypes.length > 0 ? calendarEventTypes : labels[eventType]

    let found = typeCache[labelKey]
    if (!found && typeLabels) {
      found = rFind(propEq(eventLabelRef, '_id'), typeLabels)
    }

    if (found) {
      event.color = found.color
      event.labelColor = found.color
      event.labelTextColor = found.textColor
      event.shortCode = found.shortCode
    }
    return event
  })
  return { events, locations }
}

export const resetUploadState = ({ store }) => {
  store.set(moduleState`upload`, { created: 0, updated: 0, success: null, error: '' })
}

export const setUploadErrorMessage = ({ props: { message }, store }) => {
  store.set(moduleState`upload.error`, message)
}

export const create = async ({ props: { data, entities = {} }, store, eventsService }) => {
  store.set(moduleState`upload.loading`, true)
  try {
    const { created, updated, data: events } = await eventsService.create(data)
    store.merge(moduleState`upload`, { created, updated, success: true })
    return { entities: { ...entities, events } }
  } catch (error) {
    store.set(moduleState`upload.loading`, false)
    store.set(
      moduleState`upload.error`,
      `We encountered an unexpected error importing these meals. Please try again, or contact us to let us know you're having trouble.`
    )
    throw error
  }
}

export const getBirthdays = async ({ props: { start, end }, profilesService: service, store, get }) => {
  const currentUser = get(state`account.currentUser`)
  const packages = get(state`account.packages`)
  if (currentUser?.canAccessCommandCenter && packages?.people) {
    const { data: birthdays = [] } = await service.find({ filter: 'birthdaysForTimeRange', start, end })
    store.set(moduleState`birthdays`, birthdays)
  }
}
