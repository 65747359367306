import { moduleState, state } from 'cerebral'
import errors from '@feathersjs/errors'
import debug from 'debug'
import { find, mergeDeepRight, pick, propEq } from 'ramda'
import Bluebird from 'bluebird'
import * as zoho from '../../lib/util/zoho'
import schemaSettings from '../../../shared/schemas/resort-schema'
const { resortSchema } = schemaSettings
import apiClient from '../../lib/util/api-client'

const log = debug('modules:account')
const accountServiceLocal = apiClient.service('authentication-local')

const updatableResortFields = ['address', 'logo', 'seasons', 'birthdayUpdates', 'eventSettings']

export const authenticate = async ({ props, store, get }) => {
  const invitationParams = get(moduleState`invitationParams`)
  const { access_token } = props

  try {
    log('Before authenticate')
    let params = { strategy: 'jwt', accessToken: access_token }
    if (invitationParams?.token) {
      params.invitationToken = invitationParams.token
    }
    const { user } = await apiClient.authenticate(params)
    if (user) {
      zoho.identify(user)
    }
    log('After authenticate', user)
    return { user }
  } catch (error) {
    if (error.data && !error.data.isEmailVerified) {
      store.set(state`errors.account`, error)
      return
    }
    if (error instanceof errors.NotAuthenticated) {
      throw new Error('The email or password entered are incorrect.')
    } else {
      throw error
    }
  }
}

export const associateLocal = async ({ props, accountValidation }) => {
  const { email, password } = await accountValidation.validate(props)
  const { user } = await accountServiceLocal.create({ strategy: 'local', email, password })
  return { user }
}

export const reauthenticate = async ({ accountService }) => {
  log('Before reauthenticate')
  try {
    const { user, authentication: { payload } = {} } = await accountService.reauthenticate()
    zoho.identify(user)
    log('After reauthenticate', user)
    return { user, payload }
  } catch (error) {
    // Return a NotAuthenticated error to trigger the redirect to the login page
    log('Reauthenticate error', error)
    // Wrap the caught error in a NotAuthenticated error with a message
    throw new errors.NotAuthenticated('Reauthentication error', { cause: error })
  }
}

export const setCurrentUser = ({ props: { user, payload }, store, intercom, get }) => {
  store.set(moduleState`currentUser`, user)

  const organization = get(state`account.organization`)
  intercom.setUser({ ...user, company: organization ? { id: organization?._id, name: organization?.name } : {} })

  if (payload) {
    store.set(moduleState`payload`, payload)
  }
}

export const checkOrgSlug = ({ get, store }) => {
  const user = get(moduleState`currentUser`)
  const orgSlug = get(moduleState`orgSlug`)
  if (orgSlug) {
    const selectedOrg = find(propEq(orgSlug, 'slug'), user.resorts || [])
    if (!selectedOrg) {
      store.set(moduleState`orgSlug`, null)
    }
  }
}

export const signOut = async ({ accountService, store }) => {
  await accountService.logout()
  store.set(moduleState`currentUser`, null)
  store.set(moduleState`payload`, null)
  store.set(moduleState`redirectedFromPath`, null)
}

export const changeOrganization = ({ props: { orgSlug }, store }) => {
  store.set(moduleState`orgSlug`, orgSlug)
  setTimeout(() => window.location.reload(), 500)
}

export const changeLocation = ({ props: { locationSlug }, store }) => {
  store.set(moduleState`locationSlug`, locationSlug)
  setTimeout(() => window.location.reload(), 500)
}

export const saveSettings = async ({ props: { values }, resortsService, resortSeasonsService, get, store }) => {
  const organization = get(moduleState`organization`)
  const organizationId = organization?._id

  let updatedSeasons = []
  if (values.seasons) {
    const { seasons } = values
    updatedSeasons = await Bluebird.map(
      seasons,
      async (season, index) => {
        try {
          return resortSeasonsService.patch(season._id, season)
        } catch (error) {
          store.merge(moduleState`seasons.${index}`, { status: 'error', error: error.message })
        }
      },
      { concurrency: 2 }
    )
  }

  const settingsValues = mergeDeepRight(pick(updatableResortFields, organization), values)

  if (organizationId) {
    values = resortSchema.cast(settingsValues, { stripUnknown: true })
    const result = await resortsService.patch(organizationId, values)
    const { resorts } = get(moduleState`currentUser`)
    const updatedResorts = resorts.map((resort) => (organizationId === resort?._id ? { ...resort, ...result } : resort))
    store.set(moduleState`currentUser.resorts`, updatedResorts)
  }

  if (updatedSeasons.length > 0) {
    return { entities: { seasons: updatedSeasons } }
  }
}

export const setInvitationParams = async ({ props: { token, email } = {}, store, get }) => {
  const invitationParams = get(moduleState`invitationParams`)
  store.set(moduleState`invitationParams`, { ...invitationParams, token, email })
}

export const removeInvitationParams = async ({ store }) => {
  store.set(moduleState`invitationParams`, {})
}

export const setTenant = async ({ props: { tenant = {} } = {}, store }) => {
  store.set(moduleState`tenant`, tenant)
}
