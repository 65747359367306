import { connect } from '@cerebral/react'
import { Box, Button, FormControlLabel, Grid, Radio, Typography } from '@mui/material'
import { sequences, state } from 'cerebral'
import { Field, Formik } from 'formik'
import { RadioGroup } from 'formik-mui'
import { DatePicker, TimePicker } from 'formik-mui-x-date-pickers'
import { DateTime } from 'luxon'
import { find, propEq } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { useToggleState } from '../../../lib/util/hooks'
import ConfirmDialog from '../../controls/ConfirmDialog'
import FieldSpacing from '../../elements/FieldSpacing'

const SeasonSwitch = connect(
  {
    resortSeasons: state`seasons.list.data`,
    saveSeasons: sequences`seasons.save`,
    timezone: state`account.currentUser.timezone`,
  },
  ({ resortSeasons = [], saveSeasons, timezone, displayToggler }) => {
    const [modalVisible, openModal, closeModal] = useToggleState(false)
    const switchSeason = ({ activeAfter, datePicker }) => {
      const inactiveSeason = find(propEq(false, 'active'))(resortSeasons)
      if (inactiveSeason) {
        saveSeasons({ values: { ...inactiveSeason, activeAfter, active: datePicker === 'now' } })
      }
    }

    const minDate = useMemo(() => DateTime.local({ zone: timezone }), [])

    return (
      <>
        {displayToggler ? (
          <Box mt={2} mb={4} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">Resort Seasons</Typography>
            <Button onClick={openModal} color="primary" variant="outlined" title="Switch seasons">
              Switch seasons
            </Button>
          </Box>
        ) : null}

        <Formik initialValues={{ datePicker: 'now', activeAfter: DateTime.local({ zone: timezone }) }} onSubmit={switchSeason}>
          {({ values, setFieldValue, handleSubmit }) => {
            const { datePicker } = values

            useEffect(() => {
              if (datePicker === 'now') {
                setFieldValue('activeAfter', DateTime.local({ zone: timezone }))
              }
            }, [datePicker])

            return (
              <ConfirmDialog title="Switch seasons" fullWidth onClose={closeModal} onConfirm={handleSubmit} confirmText="OK" open={modalVisible}>
                <Typography variant="body1">Do you want to switch to the new season ?</Typography>
                <Grid container>
                  <FieldSpacing>
                    <Field component={RadioGroup} name="datePicker">
                      <FormControlLabel value="now" control={<Radio />} label="Switch now" />

                      <Grid container direction="row" alignItems="center">
                        <Grid item md={3}>
                          <FormControlLabel value="date" control={<Radio />} label="Switch on" />
                        </Grid>

                        <Grid item md={9}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <Field
                                disabled={datePicker === 'now'}
                                size="small"
                                required
                                label="Date"
                                name="activeAfter"
                                format="DD"
                                variant="inline"
                                component={DatePicker}
                                minDate={minDate}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Field
                                disabled={datePicker === 'now'}
                                size="small"
                                required
                                label="Time"
                                name="activeAfter"
                                variant="inline"
                                component={TimePicker}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Field>
                  </FieldSpacing>
                </Grid>
              </ConfirmDialog>
            )
          }}
        </Formik>
      </>
    )
  }
)

export default SeasonSwitch
