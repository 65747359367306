import { state } from 'cerebral'
import * as sequences from './sequences'
import apiClient from '../../lib/util/api-client'
import authSchemaSettings from '../../../shared/schemas/authentication-schema'
const { authenticationSchema: schema } = authSchemaSettings
import { createProviders } from '../providers'
import { redirectUnauthorized } from '../factories'
import {
  packages,
  availableScreens,
  organization,
  labels,
  sections,
  eventLabels,
  eventLabelsMap,
  eventLocationCode,
  orgRoles,
  orgRolesOptions,
  season,
} from './computed'
import { showFeedbackFromError } from '../feedback/factories'
import schemaSettings from '../../../shared/schemas/resort-schema'
const { resortSchema } = schemaSettings
import seasonSchemaSettings from '../../../shared/schemas/season-schema'
const { seasonSchema } = seasonSchemaSettings

const service = apiClient.service('authentication')
const resortsService = apiClient.service('resorts')
const resortSeasonsService = apiClient.service('seasons')

export default ({ name }) => {
  // Special cases for authentication.
  const extra = {
    authenticate: (data) => apiClient.authenticate(data),
    reauthenticate: (data) => apiClient.reAuthenticate(data),
    logout: () => apiClient.logout(),
  }
  const providers = {
    ...createProviders({ name, service, extra, schema }),
    ...createProviders({ name: 'resorts', service: resortsService, schema: resortSchema }),
    ...createProviders({ name: 'resortSeasons', service: resortSeasonsService, schema: seasonSchema }),
  }

  return {
    state: {
      checked: false,
      currentUser: null,
      orgSlug: null,
      locationSlug: null,
      payload: null,
      redirectedFromPath: null,
      invitationParams: { token: null, email: null },
      packages,
      availableScreens,
      organization,
      season,
      labels,
      sections,
      eventLabels,
      eventLabelsMap,
      eventLocationCode,
      orgRoles,
      orgRolesOptions,
    },
    sequences,
    providers,
    catch: [
      redirectUnauthorized(),
      showFeedbackFromError({ title: 'We encountered an error' }),
      [
        Error,
        ({ props: { error, errorPath = name }, store }) => {
          store.set(state`errors.${errorPath}`, error)
        },
      ],
    ],
  }
}
