import { connect } from '@cerebral/react'
import { Box, Drawer, Hidden, List, ListSubheader, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Dashboard,
  EmojiEvents,
  EmojiObjects,
  Home,
  PhotoLibrary,
  AcUnit,
  BarChart,
  CloudUpload,
  Settings,
  ContactSupport,
  Receipt as RideProductsIcon,
  MenuBook,
} from '@mui/icons-material'
import VehiclesIcon from '@mui/icons-material/AirportShuttle'
import RoutesIcon from '@mui/icons-material/Place'
import UpdatesIcon from '@mui/icons-material/Notifications'
import MapIcon from '@mui/icons-material/Map'
import PublicationsIcon from '@mui/icons-material/Newspaper'
import PointOfInterestIcon from '@mui/icons-material/PersonPinCircle'
import PlaylistIcon from '@mui/icons-material/PlaylistPlay'
import SignageIcon from '@mui/icons-material/Tv'
import MobileIcon from '@mui/icons-material/Smartphone'
import { sequences, state } from 'cerebral'
import { find, flatten, path } from 'ramda'
import React, { useEffect } from 'react'
import {
  AttendanceIcon,
  CapacityIcon,
  CautionIcon,
  EventsIcon,
  GondolaIcon,
  MealsIcon,
  NotificationIcon,
  OpenInNew,
  PricingIcon,
  ReservationsIcon,
  SurveyIcon,
  TicketTypeIcon,
  TrailsIcon,
  UsersIcon,
  VideoIcon,
  WavingHand,
  HailIcon,
} from '../../assets/icons'
import brand from '../../lib/util/brand'
import { routeAccess } from '../../modules/route'
import ActionBlock from '../blocks/ActionBlock'
import NavSection from './NavSection'

const Logo = brand.logo

const locationRouteRequestBadge = ({ get }) => ({ count: get(state`locationRouteRequests.requestingCount`) })
const todaysBirthdaysBadge = ({ get }) => ({ count: (get(state`profiles.todaysBirthdays`) || []).length })

export const navItems = [
  {
    key: 'home',
    items: [{ title: 'Home', icon: Home, route: 'toRoot' }],
  },
  {
    title: 'Modules',
    items: [
      {
        title: 'Events',
        icon: EventsIcon,
        route: 'toEventCalendar',
        match: ['toEvents', 'toEvent', 'toEventCalendar'],
      },
      { title: 'Attendance', icon: AttendanceIcon, route: 'toEventInstancesSummary', match: ['toEventInstances', 'toEventAttendances', 'toEventAttendance'] },
      { title: 'Awards', icon: EmojiEvents, route: 'toAwards', match: ['toAward'] },
      {
        title: 'Meals',
        icon: MealsIcon,
        route: 'toMealsCalendar',
        match: ['toMeals', 'toMeal', 'toMealsCalendar'],
      },
      { title: 'Reservations', icon: ReservationsIcon, route: 'toReservations', match: ['toReservation'] },
      { title: 'Lifts', labelCursor: path(['lifts', 'plural']), icon: GondolaIcon, route: 'toLifts', match: ['toLift'] },
      {
        title: 'Pricing',
        icon: PricingIcon,
        route: 'toPrices',
        match: ['toPrice'],
        items: [
          { title: 'Tickets', icon: TicketTypeIcon, route: 'toPrices', match: ['toPrice'] },
          { title: 'Custom', icon: PricingIcon, route: 'toPricings', match: ['toPricing'] },
        ],
      },
      { title: 'Trails', labelCursor: path(['trails', 'plural']), icon: TrailsIcon, route: 'toTrails' },
      {
        title: 'Snow',
        icon: AcUnit,
        route: 'toSnowStats',
        items: [
          { title: 'Snow Stats', icon: BarChart, route: 'toSnowStats' },
          { title: 'Snow Reports', icon: CloudUpload, route: 'toSnowReports', match: ['toSnowReport'] },
        ],
      },
      { title: 'Albums', icon: PhotoLibrary, route: 'toAlbums', match: ['toAlbum'] },
      { title: 'Media', icon: VideoIcon, route: 'toMedias', match: ['toMedia'] },
      { title: 'Content Library', icon: MenuBook, route: 'toLibraryItems', match: ['toLibraryItem'] },
      { title: 'Resources', labelCursor: path(['resources', 'plural']), icon: EmojiObjects, route: 'toResources', match: ['toResource'] },
      { title: 'Surveys', icon: SurveyIcon, route: 'toSurveys', match: ['toSurvey', 'toSurveyResponses'] },
      { title: 'Capacity', icon: CapacityIcon, route: 'toCapacityLocations', match: ['toCapacityLocation'] },
      {
        title: 'Vehicles',
        labelCursor: path(['vehicles', 'plural']),
        icon: VehiclesIcon,
        route: 'toLocationVehicles',
        badge: locationRouteRequestBadge,
        items: [
          {
            title: 'Vehicles',
            labelCursor: path(['vehicles', 'plural']),
            icon: VehiclesIcon,
            route: 'toLocationVehicles',
            match: ['toLocationVehicle', 'toLocationVehicleView'],
          },
          { title: 'Routes', icon: RoutesIcon, route: 'toLocationRoutes', match: ['toLocationRoute', 'toLocationRouteView'] },
          { title: 'Drivers', icon: UsersIcon, route: 'toLocationVehicleDrivers', match: ['toLocationVehicleDrivers', 'toLocationVehicleShifts'] },
          {
            title: 'Shuttle Requests',
            icon: HailIcon,
            route: 'toLocationRouteRequests',
            match: ['toLocationRouteRequests'],
            badge: locationRouteRequestBadge,
          },
          {
            title: 'Ride Products',
            icon: RideProductsIcon,
            route: 'toRideProducts',
            match: ['toRideProducts'],
          },
        ],
      },
      {
        title: 'Maps',
        icon: MapIcon,
        route: 'toMapPoints',
        match: ['toPrice'],
        items: [{ title: 'Points of Interest', icon: PointOfInterestIcon, route: 'toMapPoints', match: ['toMapPoint'] }],
      },
      {
        title: 'Signage',
        icon: SignageIcon,
        route: 'toPlaylists',
        match: ['toPlaylist', 'toDisplays', 'toDisplay'],
        items: [
          { title: 'Playlists', icon: PlaylistIcon, route: 'toPlaylists', match: ['toPlaylist'] },
          { title: 'Displays', icon: SignageIcon, route: 'toDisplays', match: ['toDisplay'] },
        ],
      },
    ],
  },
  {
    title: 'Communication',
    items: [
      { title: 'Publications', icon: PublicationsIcon, route: 'toPublications', match: ['toPublication'] },
      { title: 'Welcome Screen', icon: WavingHand, route: 'toMobileWelcomes', match: ['toMobileWelcome'] },
      { title: 'Messages', icon: NotificationIcon, route: 'toNotifications', match: ['toNotification', 'toNotificationView'] },
      { title: 'Notifications', icon: UpdatesIcon, route: 'toUpdates', match: ['toUpdate'] },
      { title: 'Emergency Alerts', icon: CautionIcon, route: 'toAlerts', match: ['toAlert'] },
    ],
  },
  {
    title: 'Organization',
    items: [
      { title: 'Overview', icon: Dashboard, route: 'toOrganization' },
      { title: 'Contact Info', icon: ContactSupport, route: 'toContactInfoSettings' },
      {
        //
        title: 'People',
        icon: UsersIcon,
        route: 'toPeople',
        match: [
          //
          'toInvitation',
          'toInvitations',
          'toPerson',
          'toProfile',
        ],
        badge: todaysBirthdaysBadge,
      },
      {
        title: 'Settings',
        icon: Settings,
        route: 'toSettings',
        items: [
          {
            title: 'General',
            icon: Settings,
            route: 'toSettings',
          },
          {
            //
            title: 'Mobile App',
            icon: MobileIcon,
            route: 'toMobileSettings',
          },
          {
            //
            title: 'Events',
            icon: EventsIcon,
            route: 'toEventSettings',
          },
        ],
      },
    ],
  },
]

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
  mobileDrawer: {
    width: 256,
    '@media print': {
      display: 'none',
    },
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    '@media print': {
      display: 'none',
    },
  },
  logo: {
    width: 100,
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  icon: {
    width: 24,
    '& path, & g': {
      fill: 'currentColor',
      fillOpacity: 1,
    },
  },
}))

const Nav = connect(
  {
    mobileNavOpen: state`ui.mobileNavOpen`,
    setMobileNavOpen: sequences`ui.setMobileNavOpen`,
    navShown: state`route.navShown`,
    navOffscreen: state`route.navOffscreen`,
    currentRoute: state`route.key`,
    packages: state`account.packages`,
  },

  ({ setMobileNavOpen, ...props }) => {
    return { ...props, closeMobileNav: () => setMobileNavOpen({ open: false }) }
  },

  ({ mobileNavOpen, closeMobileNav, currentRoute, navShown, navOffscreen, packages }) => {
    const classes = useStyles()

    useEffect(() => {
      if (mobileNavOpen) {
        closeMobileNav()
      }
    }, [currentRoute])

    const content = (
      <Box height="100%" display="flex" flexDirection="column">
        <Hidden lgUp>
          <Box display="flex" justifyContent="center" px={2} mt={4}>
            <Logo className={classes.logo} />
          </Box>
        </Hidden>

        <Box p={2}>
          {navItems.map(({ key, title, items }) => {
            const routes = flatten(items.map(({ route, match }) => route || match))
            const enabled = Boolean(find((route) => routeAccess[route](packages), routes))

            const subheader = Boolean(title) && (
              <ListSubheader disableGutters disableSticky>
                {title}
              </ListSubheader>
            )

            return (
              enabled && (
                <List key={key || title} subheader={subheader}>
                  <NavSection items={items} packages={packages} />
                </List>
              )
            )
          })}
        </Box>
        <Box flex={1} />

        {brand && brand.docsUrl && (
          <Box p={2}>
            <ActionBlock endIcon={<OpenInNew className={classes.icon} />} title="Help Center" href={brand.docsUrl} target="_blank">
              View documentation, videos, and more.
            </ActionBlock>
          </Box>
        )}

        <Box p={2} pt={0} display="flex" justifyContent="center">
          <Typography variant="caption" color="textSecondary">
            Version {import.meta.env.VERSION}
          </Typography>
        </Box>
      </Box>
    )

    return navShown ? (
      <>
        <Hidden lgUp>
          <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={closeMobileNav} open={mobileNavOpen} variant="temporary">
            {content}
          </Drawer>
        </Hidden>
        <Hidden lgDown>
          <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open={!navOffscreen} variant="persistent">
            {content}
          </Drawer>
        </Hidden>
      </>
    ) : null;
  }
)

export default Nav
